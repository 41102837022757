// Rooms

.room-module {
    position: relative;

    .category-list {
        margin-bottom: 30px;

        .categories {
            display: flex;
            flex-wrap: wrap;

            .category-entry {
                text-decoration: none;
                position: relative;
                font-weight: 300;
                margin: 10px 25px 10px 0;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    height: 2px;
                    left: 0;
                    right: 100%;
                    background-color: $dark;
                    transition: right $transition-time $transition-ease;
                }

                &:hover,
                &.active {
                    color: $gold;

                    &::after {
                        right: 30%;
                        background-color: $gold;
                        transition: right $transition-time $transition-ease;
                    }
                }
            }

            .category-entry:first-of-type {
                margin-left: 0;
            }
        }
    }



  .ajax-filtered-elements {
    .room {
      .image-people-wrapper {
        .room-title {
          transform: rotate(-90deg);
        }
      }
    }
  }

    .room-list {
        .room_wrapper {
            display: none;

            &.show {
                display: block;
            }
        }

        .room {
            .image-people-wrapper {
                position: relative;
                //overflow: hidden;

                .room-title {
                    position: absolute;
                    left: 0;
                    bottom: 40px;
                    z-index: 100;
                    //transform: rotate(90deg);
                    transform-origin: left;
                    color: $white;
                    font-weight: 300;
                    font-family: $font-family-sans-serif, sans-serif;

                    @include media-breakpoint-up(md) {
                        left: -10px;
                    }
                }

                .image {
                    position: relative;
                    z-index: 0;
                    margin-bottom: 20px;
                    overflow: initial;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: linear-gradient(to top, rgba(0,0,0,.55) 0, rgba(0,0,0,0) 40%);
                    }

                   /* &:before {
                        content: "";
                        height: 230px;
                        right: -10px;
                        bottom: -1px;
                        left: 0;
                        background-image: url(/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/Icons/Freigold_Logo_i_white.svg);
                        background-position: 10t0% -2px;
                        background-size: contain;
                        z-index: 11;
                        background-repeat: no-repeat;
                        position: absolute;
                        pointer-events: none;
                        -webkit-transform: scaleY(-1);
                        transform: scaleY(-1);
                    }*/

                    img {
                        filter: brightness(.7);
                        transform: scale(1);
                        transition: transform $transition-time $transition-ease;
                    }
                }
            }

            .description {
                margin-bottom: 20px;
            }

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }

            &:hover {
                text-decoration: none;

                .image-people-wrapper {
                    img {
                        transform: scale(1.1);
                        transition: transform $transition-time $transition-ease;
                    }
                }
            }
        }
    }
}



